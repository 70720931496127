
$white: #FFFFFF;
$navy : #192835;
$blue: #02CCE6;

.logo {
  display: block;
  width: 100px;
  text-align: left;
  margin-left: 15px;
  margin-top: 0;
  cursor: pointer;

  img {
    width: 75px;
  }
}

.hideMobile {
  display: none;
}

.bm-burger-button {
  width: 45px;
  display: inline-block;
  right: 20px;
  position: fixed;
  top: 10px;
}

.bm-burger-bars {
  background: $white;
  height: 3px;
}

.bm-burger-bars-hover {
  background: $navy;
}

.bm-menu {
  background: $blue;
  color: $navy;
  padding: 20px;
}

.bm-menu-wrap {
  top: 0;
}

.bm-item-list {
  margin-top: 60px;
  height: 80% !important;
}

.bm-item {
  display: inline-block;
  font-size: 20px;

  ul {
    list-style: none;
    li {
      a {
        color: $navy;
        font-size: 20px;
      }
    }
  }
}

.bm-overlay {
  display: none;
}

.bm-cross {
  background: $navy;
}


.menuItem {
  margin: 25px 0;
  &:before,
  &:after {
    bottom: -8px;
  }
}

.hamburger-menu {
  display: block;
  height: 40px;
  padding: 20px 0 10px 0;
}

.mobile-logo {
  margin-left: 15px;
  margin-top: 10px;
  a {
    text-transform: uppercase;
    letter-spacing: .03em;
    font-size: 18px;
    color: $white;
    text-decoration: none;
  }
}
