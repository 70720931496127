@font-face {
  font-family: 'nexa_bold';
  src: url('../src/assets/fonts/nexa_bold-webfont.woff') format('woff2'),
       url('../src/assets/fonts/nexa_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'nexa_light';
  src: url('../src/assets/fonts/nexa_light-webfont.woff2') format('woff2'),
       url('../src/assets/fonts/nexa_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  font-family: 'nexa_bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background: #192835;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 1024px) {
  .react-responsive-modal-modal {
    width: 90vw !important;
    margin: 0 !important;
    box-sizing: border-box;
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

video {
  max-width: none !important;
}


.fadedNav {
  opacity: 0;
  transition: opacity 200ms;
}

.nav-enter {
  opacity: 1 !important;
  transition: opacity 500ms;
  z-index: 120;
  position: fixed;
}

.nav-enter-done{
  opacity: 1 !important;
  transition: opacity 200ms;
  z-index: 120;
  position: fixed;
} 

.nav-enter-active {
  opacity: 1 !important;
  transition: opacity 200ms;
  z-index: 120;
  position: fixed;
}
.nav-exit {
  opacity: 0;
  transition: opacity 200ms;
  z-index: 120;
  position: fixed;
}
.nav-exit-active {
  opacity: 0;
  transition: opacity 200ms;
  z-index: 120;
  position: fixed;
}

.nav-exit-done {
  opacity: 0;
  transition: opacity 200ms;
  z-index: 120;
  position: fixed;
}
